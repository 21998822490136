:root {
    --font-family-monospace: "Titillium Web", sans-serif;
    --main-color: #be207b;
    --on-main-color: #fff;
    --second-color: #8cb9a8;
}

html, body {
    font-family: var(--font-family-monospace);
    background-color: var(--main-color);
    min-height: 100vh;
    /*width: 100vw;*/
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    color: var(--on-main-color);
}

#root {
    max-width: 340px;
    flex-grow: 1;
    background-color: #ffffff7d;
    border-radius: 0 20px 0;
}

@media (min-width: 800px) {
    #root {
        max-width: 800px;
    }

    #content {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }

    #content .onAir {
        max-width: 340px;
    }

    #outlet {
        flex-grow: 2;
        padding: 0 20px;
    }
}


a, a:visited, a:hover, a:active {
    color: var(--on-main-color);
}

h1, h2, h3, h4 {
    margin: 0;
    color: var(--on-main-color);
    font-size: 1.2em;
}

img {
    max-width: 100%;
}

.tc {
    text-align: center;
}

.main {
    padding: 20px;
}

nav {
    display: block;
    text-align: center;
}

nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 10px 0;
    padding: 10px 0;
}

/*nav ul li,*/
nav ul li a {
    text-decoration: none;
    text-transform: uppercase;
    background-color: var(--main-color);
    color: var(--second-color);
    border: 1px solid transparent;
    border-radius: 20px;
    padding: 5px 20px;
    margin: 5px 10px;
    font-size: 10px;
}

.onAir {
    color: var(--on-main-color);
    text-align: center;
}

.onAir figure {
    display: flex;
    /*min-height: 400px;*/
    align-items: center;
    width: 100%;
    margin: 0;
    min-height: 200px;
}

#currentSongInfo {
    display: block;
    padding: 20px 0;
}

#currentSongInfo span {
    display: block;
}

#currentSongName {
    font-size: 1.5em;
    font-weight: lighter;
}

#currentSongAuthor {
    font-size: 1.1em;
    display: block;
    line-height: 2;
}

.controls-wrapper {
    background: url('../img/mc-header-green.png') bottom right no-repeat;
    background-size: 140px 40px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.controls-wrapper img {
    width: 50%;
    display: inline-flex;
    align-self: start;
}

.controls-wrapper .controls {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.controls-wrapper .controls button {
    border-width: 0;
    outline: none;
    background-color: var(--on-main-color);
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button .isPlaying {
    margin-left: 8px;
}

.headerWithClose {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px 0;
    padding: 10px 0;
    border-top: 1px solid var(--on-main-color);
    border-bottom: 1px solid var(--on-main-color);
}

.headerWithClose .btn {
    text-decoration: none;
}

.headerWithClose .text {
    text-transform: uppercase;
    font-weight: lighter;
    text-align: center;
}


.playedItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    line-height: 1.2;
    color: var(--on-main-color);
}

.playedItem .what {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.playedItem .when {
}

.playedItem .playedItemPreview {
    padding: 0;
    margin: 0;
}

.playedItem .playedItemPreview img {
    width: 50px;
}

.playedItem b {
    font-weight: lighter;
    font-size: 90%;
}

.playedItem .author {
    font-weight: normal;
    color: var(--main-color);
    font-size: 80%;
}

.playedItem .playedItemPreview {
    display: block;
}

.emails span {
    display: block;
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
    color: var(--on-main-color);
}

p.txt {
    color: var(--on-main-color);
}